import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getDomain } from 'tldts'
import free from '@/assets/plan-free.svg'
import small from '@/assets/plan-small.svg'
import medium from '@/assets/plan-medium.svg'
import large from '@/assets/plan-large.svg'

dayjs.extend(relativeTime)

export function scanStatus(scan) {
  const status = scan.status || scan.get?.('status')
  const reviewedAt = scan.reviewedAt || scan.get?.('reviewedAt')
  if (status === 'pending') {
    return 'Pending'
  }
  if (status === 'paused') {
    return 'Paused'
  }
  if (status === 'onhold') {
    return 'Onhold'
  }
  if (status === 'errored') {
    return 'Errored'
  }
  if (['scanning', 'predicting', 'scanned'].includes(status)) {
    return 'Scanning'
  }
  if (status === 'completed') {
    if (reviewedAt) {
      return 'Completed & Reviewed'
    }
    return 'Completed'
  }
  return status
}

export function scanStatusIcon(scan) {
  const status = scan.status || scan.get?.('status')
  const reviewedAt = scan.reviewedAt || scan.get?.('reviewedAt')
  if (status === 'pending') {
    return 'mdi-timer-sand'
  }
  if (status === 'paused') {
    return 'mdi-pause-circle-outline'
  }
  if (status === 'onhold') {
    return 'mdi-pause-circle'
  }
  if (status === 'errored') {
    return 'mdi-alert-circle-outline'
  }
  if (status === 'completed') {
    if (reviewedAt) {
      return 'mdi-check-all'
    }
    return 'mdi-check'
  }
  if (['scanning', 'scanned'].includes(status)) {
    return 'mdi-loading'
  }
  return ''
}

export function scanStatusColor(status) {
  return {
    pending: '',
    paused: 'warning',
    onhold: 'warning',
    errored: 'error',
    scanning: 'success lighten-1',
    scanned: 'success lighten-1',
    completed: 'success'
  }[status] || ''
}

export function scanTypeIcon(type) {
  return {
    welcome: 'mdi-heart-search',
    weekly: 'mdi-numeric-7-circle-outline',
    daily: 'mdi-hours-24',
    hourly: 'mdi-clock-fast',
    crawl: 'mdi-text-search-variant',
    raid: 'mdi-location-enter',
    'reverse-image': 'mdi-image-filter-center-focus-strong',
    legacy: 'mdi-history',
    training: 'mdi-gesture-double-tap'
  }[type] || ''
}

export function fuzzyStatus({ scrapeError, features, label, category, labeledBy, skipReview }) {
  if (scrapeError) {
    return `Errored: ${JSON.stringify(scrapeError)}`
  }
  if (features.tf) {
    return `${features.tf.total} usernames`
  }
  if (!label || !['google-search', 'google-images', 'related-images'].includes(category)) {
    return 'Not applicable'
  }
  if (labeledBy) {
    return 'Not necessary (already reviewed)'
  }
  if (skipReview) {
    return 'Not necessary'
  }
  return 'Scrape Pending'
}

export const subscriptionStatus = (subscription) => {
  return {
    active: 'Active',
    trialing: 'Trialing',
    past_due: 'Past Due',
    unpaid: 'Unpaid',
    canceled: 'Canceled',
    incomplete: 'Incomplete',
    incomplete_expired: 'Incomplete Expired'
  }[subscription?.status] || '-'
}
const PLAN_ICONS = { free, small, medium, large }
export const planIcon = plan => PLAN_ICONS[plan || 'free']

// globally registered filters
const number = (amount, zero = '-') => !isNaN(amount)
  ? new Intl.NumberFormat().format(amount)
  : zero

const currency = (amount, zero = '-', locale) => !isNaN(amount)
  ? new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount)
  : zero

export const healthTextColor = (healthScore) => {
  if (healthScore >= 70) return '#3FBE53'
  if (healthScore >= 50) return '#EDBE20'
  return '#FF8484'
}
export const healthColor = (healthScore) => {
  if (healthScore >= 70) return 'linear-gradient(90deg, #D7ECDE 0%, #3FBE53 100%)'
  if (healthScore >= 50) return 'linear-gradient(90deg, #F1EDD9 0%, #EDBE20 100%)'
  return 'linear-gradient(90deg, #FFE4E4 0%, #FF8484 100%)'
}

export default {
  number,
  currency,
  date(value) {
    if (!value) return '-'
    return new Date(value?.iso || value).toLocaleDateString()
  },
  datetime(value) {
    if (!value) return '-'
    const date = new Date(value?.iso || value)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  },
  fromNow(value, textIfPast) {
    if (!value) return '-'
    const date = dayjs(value?.iso || value)
    if (date.isBefore(dayjs())) {
      return textIfPast || date.fromNow()
    }
    return date.fromNow()
  },
  from(value, compare) {
    if (!value) return '-'
    return dayjs(value?.iso || value).from(compare, true)
  },
  imageUrl(value) {
    const url = value?._url
    return url.replace('http://0.0.0.0:1338/', 'https://bruqi.isokosan.com/')
  },
  pluralize(value, singular, plural, zero = 'No') {
    return `${number(value, zero)} ${value === 1 ? singular : plural}`
  },
  dictToArr(dict) {
    return Object.entries(dict).map(([value, text]) => {
      return { value, text }
    })
  },
  plan: value => ({
    free: 'DIY',
    small: 'Starter',
    medium: 'Creator Pro',
    large: 'Top 1%'
  })[value || 'free'],
  linkFavicon(link) {
    try {
      return `https://icons.duckduckgo.com/ip3/${getDomain(link)}.ico`
    } catch {
      return ''
    }
  },
  domainFavicon(domain) {
    return `https://icons.duckduckgo.com/ip3/${domain}.ico`
  },
  bruqiCdn: (url) => {
    // if the domain of the requested resource is instagrams cdn then proxy via bruqi cdn
    try {
      const domain = new URL(url).hostname
      if (domain.includes('cdninstagram')) {
        return import.meta.env.VITE_API_BASE_URL + '/cdn/p/' + encodeURIComponent(url)
      }
      return url
    } catch (error) {
      console.error(error)
      return url
    }
  }
}
