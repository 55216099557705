const apiKey = import.meta.env.VITE_REWARDFUL_API_KEY

export default function () {
  if (!document.getElementById('rewardful-script')) {
    (function (w, r) {
      w._rwq = r
      w[r] = w[r] || function () {
        (w[r].q = w[r].q || []).push(arguments)
      }
    })(window, 'rewardful')
    const script = document.createElement('script')
    script.id = 'rewardful-script'
    script.async = true
    script.src = 'https://r.wdfl.co/rw.js'
    script.setAttribute('data-rewardful', apiKey)
    document.head.appendChild(script)
  }
}
